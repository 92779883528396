@use "../config" as *;
.waf-listing:not(.waf-hero-listing) {
    @extend %p-y-14-24;
    @extend %px-2;
    @extend %mb-1;
    .layout-wrapper {
        max-width: 100%;
    }
    .title {
        @include title-with-icon(null, megaphone-fill, 20, null);
        @extend %title-40;
        &::before {
            color: var(--neutral-40);
            justify-content: flex-start;
            @extend %pl-2;
            @extend %mb-4;
        }
    }
    .head-tab {
        @extend %d-none;
    }
}
.waf-races {
    @extend %bg-neutral-5;
    .layout-wrapper {
        @extend %p-y-14-24;
    }
    .desc {
        @extend %d-none;
    }
    .swiper-button-prev,
    .swiper-button-next {
        display: flex;
    }
}
@include mq(col-tablet) {
    .waf-listing:not(.waf-hero-listing) {
        padding-inline: 0;
        .layout-wrapper {
            max-width: var(--container-max-width);
        }
        .title {
            font-size: 4rem;
        }
        .head-wrap {
            flex-basis: 60%;
            width: 60%;
        }
        .article {
            &-list {
                grid-template-columns: repeat(2, 1fr);
                gap: var(--space-6);
            }
        }
    }
    .waf-races {
        .title {
            font-size: 4rem;
        }
        .layout-wrapper {
            padding-block: var(--space-14) var(--space-24);
        }
    }
}
@include mq(col-lg) {
    .waf-listing:not(.waf-hero-listing) {
        padding-block: 10.4rem var(--space-24);
        .head-wrap {
            margin-bottom: var(--space-18);
        }
    }
    .waf-races {
        .layout-wrapper {
            padding-block: var(--space-24);
        }
    }
    .waf-listing {
        &.waf-overlay-list {
            .article {
                &-list {
                    grid-template-columns: repeat(4, 1fr);
                }
            }
        }
    }
}