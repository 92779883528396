@use "../config" as *;
.waf-hero-listing {
    margin-top: var(--secondary-header-height);
    @extend %bg-neutral-0;
    &.waf-component {
        margin: 0 var(--space-2-neg);
        @extend %p-0;
    }
    .waf-head {
        @extend %d-none;
    }
    .waf-body {
        @extend %relative;
    }
    .layout-wrapper {
        max-width: unset;
        margin: 0;
    }
    .showcase-wrapper {
        @extend %relative;
    }
    .swiper {
        &-button-prev,
        &-button-next {
            @extend %d-none;
        }
    }
    .img-box {
        border-radius: 0;
        @extend %h-100;
    }
    .article {
        &-item {
            border: 0;
            border-radius: 0 0 var(--space-4) var(--space-4);
            @extend %hidden;
            &:hover {
                .article-thumbnail {
                    img {
                        transform: scale(1);
                    }
                }
            }
        }
        &-content {
            width: var(--container-max-width);
            transform: translateX(-50%);
            @include position(null, null, var(--space-12), 50%);
            @extend %w-100;
            @extend %p-0;
            @extend %flex-column;
            .tagline {
                order: 3;
                @extend %mt-8;
                a {
                    width: fit-content;
                    background-color: var(--neutral-0);
                    color: var(--primary-50);
                    @extend %capitalize;
                    @extend %btn-fill;
                    &:hover {
                        color: var(--neutral-0);
                    }
                }
            }
        }
        &-thumbnail {
            // height: calc(.85 * max(var(--window-inner-height), 60rem));
            height: 58rem;
            @extend %common-gradient;
            img {
                object-fit: cover;
                object-position: center;
                @extend %w-100;
                @extend %h-90;
                @extend %relative;
            }
        }
        &-title {
            height: auto;
            @include truncate-text(3);
            @extend %title-40;
            @extend %mb-2;
            @extend %neutral-5;
        }
        &-meta {
            @include flex-config(flex, row-reverse, flex-end, center);
            @extend %gap-2;
            > *:not(:first-child) {
                &::before {
                    right: -0.6rem;
                    @extend %position-y-center;
                    @extend %bg-neutral-5;
                }
            }
            .meta {
                @extend %text-l;
                @extend %neutral-5;
            }
        }
    }
}
@include mq(col-tablet) {
    .waf-hero-listing {
        margin-top: 0;
        .article {
            &-item {
                border-radius: 0 0 var(--space-8) var(--space-8);
            }
            &-thumbnail {
                // height: max(var(--window-inner-height), 63rem);
                height: 60rem;
                img {
                    object-position: top center;
                }
            }
            &-list {
                display: block;
            }
            &-content {
                > a {
                    width: 70%;
                    display: block;
                }
            }
            &-title {
                font-size: 3.2rem;
            }
            &-description {
                width: 45%;
            }
        }
    }
}
@include mq(col-xl) {
    .waf-hero-listing {
        .article {
            &-title {
                font-size: 5.6rem;
                // height: 21.9rem;
                line-height: 1.3;
            }
            &-content {
                bottom: var(--space-20);
            }
        }
    }
}