@forward './main.scss';
@forward './swiper/swiper-bundle';
@forward './events/fourstar-shoswcase';
@forward './events/the-races';
@forward './listing/hero-listing';
@forward "./listing/content-listing-common";
.waf-listing.waf-overlay-list {
  .item-type-video {
    .icon-time-wrapper {
      order: -1;
      @extend %text-m;
      @extend %ml-1;
    }
    .article-meta {
      order: -2;
      @extend %text-m;
    }
    .article-content {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
    }
  }
}